import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DateRange } from '../../../../shared/components';
import { LPGDistData, LpgDistReportSummary, LPGDistService, Payment, ToolbarItem } from './../../../lpg-dist.service';
import * as momentJ from 'moment-jalaali';
import { Organisation, OrganisationService } from '../../../../organisation/organisation.service';
import {  Router } from '@angular/router';
import { Subject} from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { DataTablesService } from '../../../../shared/datatables/data-tables.service';
import { LpgDistToolbarComponent } from 'src/app/lpg-dist/lpg-dist-toolbar/lpg-dist-toolbar.component';
import { UserLocalStoreService } from 'src/app/shared/services/user-local-store.service';
import {
  PgDateRangeTitleComponent, PgDateRangeTitleEnum, PgTitleComponent,
  PgToolbarComponent
} from '../../../../shared/components/title-bar/title-bar.component';
import { saveAs } from 'file-saver';


interface LpgDistPaymentDetailComponentSettings {
  companyId: string;
}

@Component({
  selector: 'app-lpg-dist-payment-detail',
  templateUrl: './lpg-dist-payment-detail.component.html',
})
export class LpgDistPaymentDetailComponent implements OnInit, OnDestroy,
  PgTitleComponent, PgDateRangeTitleComponent, PgToolbarComponent {

  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;

  title = new EventEmitter<string>();
  dateRangeSubTitle = new EventEmitter<[DateRange, PgDateRangeTitleEnum]>();
  toolbarComponentType = LpgDistToolbarComponent;
  toolbarComponent: LpgDistToolbarComponent;

  startDate: Date;
  endDate: Date;
  company: Organisation;
  selectedOrganisation: Organisation;

  companies: Organisation[] = [];
  total: number;
  paymentSummary: LpgDistReportSummary[] = [];
  loading: LPGDistData[] = [];
  edit: boolean;
  paymentData: Payment;

  dataTable: Payment[];

  showEntry: boolean;
  dateRange: DateRange;
  organisations: Organisation[];


  dtOptions = {};
  dtTrigger: Subject<any> = new Subject<any>();
  isDataTablesInitialised = false;

  _settings: LpgDistPaymentDetailComponentSettings;


  constructor(private _lpgDistService: LPGDistService,
    private _router: Router,
    private _organisationService: OrganisationService,
    private _userLocalStorage: UserLocalStoreService) {}


  intitPage() {
      this._settings = this._userLocalStorage.get<LpgDistPaymentDetailComponentSettings>('LpgDistPaymentDetailComponent');
      if (!this._settings) {
        this._settings = { companyId: 'iran.parsagroup.orsagas', };
      }
      const endDate = momentJ().endOf('day').toDate().getTime();
      const startDate  = momentJ(endDate).startOf('jMonth').toDate().getTime();
      this.dateRange = {
        startDate: new Date(startDate),
        endDate: new Date(endDate)
      };
      this._organisationService.get().subscribe(c => {
        this.organisations = c;
        this.selectedOrganisation =  this.organisations.find(co => co.name === this._settings.companyId);
        this.toolbarComponent.dateRange = {
          startDate: this.dateRange.startDate,
          endDate: this.dateRange.endDate
        };
        this.toolbarComponent.jalali = true;
        this.toolbarComponent.singleDate = false;
        this.toolbarComponent.visibleOrganisation = true;
        this.updateTable();
        this.toolbarComponent.organisations = this.organisations
        .filter(o =>  ['domestic'].filter(s => o.properties[s]).length > 0);
        this.toolbarComponent.selectedOrganisation =  this.organisations.find(co => co.name === this._settings.companyId);
        this.toolbarComponent.itemsSelected.subscribe (t => {
          this.refresh(t);
        });
      });
  }

  updateTable() {
    this.title.emit('Payments List');
    this.dateRangeSubTitle.emit([this.dateRange, PgDateRangeTitleEnum.persian]);
    this._lpgDistService.getPaymentHistory(this.selectedOrganisation.name,
      this.dateRange.startDate.getTime(),
      this.dateRange.endDate.getTime()).subscribe(sum => {
        this.dataTable = this.sortArray(sum);
        if (!this.isDataTablesInitialised) {
          this.dtTrigger.next(false);
        } else {
          this.dtElement.dtInstance.then((dtInstance) => {
            dtInstance.destroy();
            this.dtTrigger.next(false);
          });
        }
        this.isDataTablesInitialised = true;
      });
  }

  ngOnInit() {
    const currentComponent = this;
    const buttons = [
        {
          text: 'خروجی اکسل',
          action: () => currentComponent.xlsxReport()
        }
      ];
    this.dtOptions = DataTablesService.getOptions(buttons);
    this.intitPage();
  }

  organisationValueFormatter(data: Organisation) {
    return data.nameTranslations['fa'];
  }

  selectedPaymentDateChanged(dr: DateRange) {
    this.startDate = dr.startDate;
    this.endDate = dr.endDate;
  }

  getTotalWight() {
    this.total = this.dataTable.reduce(((previousValue, currentValue) =>
      previousValue + currentValue.totalWeight), 0);
  }

  editPayment(selectedRecord: Payment) {
    this._router.navigate(['/lpg-dist/payments/edit',
      encodeURIComponent(selectedRecord.companyId),
      encodeURIComponent(selectedRecord.payTimestamp.toString()),
      encodeURIComponent(selectedRecord.paymentBillNo)])
      .then(_ => _);
  }

  deletePayment(payment: Payment) {
    payment.isDeleted = true;
    this._lpgDistService.updatePayment(payment).subscribe(() => {
      this.updateTable();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  fotmatOrganisation(organisationId: string) {
    const organisation = this.organisations.find(o => o.name === organisationId);
    return (organisation !== undefined) ? organisation.nameTranslations['fa'] : '';
  }

  xlsxReport() {
    this._lpgDistService.downloadPaymentsExcel(
      this.selectedOrganisation.name,
      this.dateRange.startDate.getTime(),
      this.dateRange.endDate.getTime()).subscribe(file => {
        saveAs(file.body, `lpg-dist-payments.xlsx`);
      });
  }

  sortArray(data: Payment[]) {
    const test = data.sort((l1, l2) => {
      if (l1.payTimestamp < l2.payTimestamp) {
        return -1;
      }
      if (l1.payTimestamp > l2.payTimestamp) {
        return 1;
      }
      return 0;
    });
    return test;
  }

  refresh(item: ToolbarItem) {
    if(item.companyId) {
      this._settings.companyId = item.companyId;
      this._userLocalStorage.save('LpgDistPaymentDetailComponent', this._settings);
      this.selectedOrganisation = this.organisations.find(o => o.name === item.companyId);
    }
    this.dateRange = item.dateRange;
    this.updateTable();
  }
}
