<form #discharge="ngForm"
  class="form-horizontal">
  <fieldset style="border: 0;"
    [disabled]="isVerified || !hasRole('vessels-voyages-editor')">
    <!--Date-->
    <div class="form-group row">
      <label for="selectedDate"
        class="col-form-label col-md-2 ">Date</label>
      <div class="col-md-10">
        <app-date-range id="selectedDate"
          [singleDateSelector]="true"
          [dateRange]="dischargeDate"
          (dateRangeChange)="selectDate($event)"
          required
          [disabled]="mode === 'edit'"
          [localeDateFormat]="'YYYY-MM-DD'">
        </app-date-range>
      </div>
    </div>
    <!--Port-->
    <div class="form-group row">
      <label for="port2"
        class="col-form-label col-md-2 ">Port</label>
      <div class="col-md-10">
        <app-organisation-selector
          class="pg-component"
          [(organisation)]="port"
          [organisationLanguage]="'en'"
          name="port2"
          id="port2"
          [disabled]="mode === 'edit'"
          (organisationChange)="dischargeLocation($event)"
          [organisations]="portOrganisations"
          required>
        </app-organisation-selector>
      </div>
    </div>
    <!--Consignee-->
    <div *ngIf="!isSts">
      <div class="form-group row">
        <label for="consignee"
          class="col-form-label col-md-2 ">Consignee</label>
        <div class="col-md-10">
          <app-organisation-selector
            class="pg-component"
            [(organisation)]="consignee"
            [organisationLanguage]="'en'"
            id="consignee"
            name="consignee"
            [organisations]="consignees"
            required>
          </app-organisation-selector>
        </div>
      </div>
    </div>
    <!--Daughter vessel STS-->
    <div *ngIf="isSts">
      <div class="form-group row">
        <label for="DaughterVessel"
          class="col-form-label col-md-2 ">Daughter Vessel</label>
        <div class="col-md-10">
          <select class="form-control"
            name="DaughterVessel"
            id="DaughterVessel"
            [disabled]="mode === 'edit'"
            (change)="getDaughterVesselVoyages($event.target.value)"
            [(ngModel)]=_selectedDischarge?.sts.vesselIMO
            required>
            <option *ngFor="let vessel of vessels" value="{{vessel.IMO}}">
              {{vessel.name.name}}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label for="voyageNo"
          class="col-form-label col-md-2 ">Voyage NO</label>
        <div class="col-md-10">
          <select class="form-control"
            name="voyageNo"
            id="voyageNo"
            [(ngModel)]="_selectedDischarge?.sts.shipVoyageNo"
            required>
            <option *ngFor="let voyage of daughterVesselVoyages"
              value="{{voyage.shipVoyageNo}}">
              {{voyage.vesselName}}-{{voyage.shipVoyageNo}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <!--Surveyors-->
    <div class="col-md-10 pull-right mt-3">
      <table class="col-md-10 table table-sm table-striped table-bordered text-center">
        <thead>
          <tr>
            <td *ngIf="_selectedDischarge.measurements.length > 1 && !isVerified"></td>
            <td>Provider type</td>
            <td>Provider</td>
            <td>Product</td>
            <td>Weight</td>
            <td>Primary</td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let measurement of measurements let i=index">
            <td *ngIf="_selectedDischarge.measurements.length > 1 && !isVerified">
              <!--              <a *ngIf="!isVerified" (click)="editMeasurement(i)" >-->
              <!--                <i class="fa fa-pencil"></i>-->
              <!--              </a>-->
              <ng-container>
                <a *ngIf="!isVerified" (click)="removeMeasurement(i)">
                  <i class="fa fa-trash-o"></i>
                </a>
              </ng-container>
            </td>
            <td>{{measurement.item.provider}}</td>
            <td>
              <ng-container *ngIf="measurement.isSaved">
                {{providerFormatter(measurement.item)}}
              </ng-container>
              <ng-container
                *ngIf="!measurement.isSaved && (measurement.item.provider === 'Surveyor' ||
              measurement.item.provider === 'Surveyor Loading')">
                <app-auto-complete
                  id="surveyor{{i}}"
                  [formatter]="organisationValueFormatter"
                  [ngModel]="measurement.item.providerId"
                  (ngModelChange)="selectedMeasurementProviderChanged(measurement, $event)"
                  [items]="surveyors"
                  name="surveyor{{i}}">
                </app-auto-complete>
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="measurement.isSaved">
                {{measurement.item.product}}
              </ng-container>
              <ng-container *ngIf="!measurement.isSaved">
                <select class="form-control"
                  name="product{{i}}"
                  id="product{{i}}"
                  [(ngModel)]="measurement.item.product"
                  required>
                  <option *ngFor="let product of productNames "
                    value="{{product}}">
                    {{product}}
                  </option>
                </select>
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="measurement.isSaved">
                {{measurement.item.weight}}
              </ng-container>
              <ng-container *ngIf="!measurement.isSaved">
                <input
                  type="text"
                  class="form-control"
                  name="weight{{i}}"
                  id="weight{{i}}"
                  [(ngModel)]="measurement.item.weight"
                  required>
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="measurement.isSaved">
                <i *ngIf="measurement.item.primary" class="fa fa-check"></i>
              </ng-container>
              <ng-container *ngIf="!measurement.isSaved">
                <input id="primary"
                  name="primary"
                  type="checkbox"
                  class="btn-link"
                  (change)="measurement.item.primary=!measurement.item.primary"
                  [checked]="measurement.item.primary">
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--Assigned BLs-->
    <div class="col-md-10 pull-right mt-3" *ngIf="assignedBillOfLadings && assignedBillOfLadings.length > 0">
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <td></td>
            <td>No</td>
            <td>Product</td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let bl of assignedBillOfLadings let i=index">
            <td>
              <ng-container
                *ngIf="!isVerified && hasRole('vessels-voyages-editor')">
                <a (click)="removeAssignedBL(i)">
                  <i class="fa fa-trash-o"></i>
                </a>
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="bl.item">
                {{bl.item.no}}
              </ng-container>
              <ng-container *ngIf="!bl.item">
                <select class="form-control me-1"
                  name="temporaryBillOfLadingSelection"
                  (ngModelChange)="selectedAssignedBLChanged(i, $event)"
                  [ngModel]="_selectedVoyage.billOfLadings">
                  <option *ngFor="let bl of availableBillOfLadingsToAssign"
                    [ngValue]="bl">{{bl.no}}</option>
                </select>
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="bl.item">
                <ng-container *ngFor="let p of bl.item.products">
                  {{p.product}}: {{p.grossWeight | number}} <br>
                </ng-container>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </fieldset>
  <div class="btn-group pull-right mt-3" role="group"
    aria-label="Button group with nested dropdown">
    <ng-container class="btn-group" role="group"
      *ngIf="hasRole('vessels-voyages-editor')">
      <button class="btn btn-outline-secondary btn-light dropdown-toggle me-1"
        type="button" id="addButtonGroup" data-bs-toggle="dropdown"
        aria-expanded="false">
        Add
      </button>
      <div class="dropdown-menu" aria-labelledby="addButtonGroup">
        <a class="dropdown-item"
          (click)="addNewMeasurement('Mother Vessel Figure')"
          *ngIf="isSts">Mother Vessel Figure</a>
        <a class="dropdown-item"
          (click)="addNewMeasurement('Daughter Vessel Figure')"
          *ngIf="isSts">Daughter Vessel Figure</a>
        <a class="dropdown-item"
          (click)="addNewMeasurement('Surveyor')">Surveyor Figure Discharge
          Density</a>
        <a class="dropdown-item"
          (click)="addNewMeasurement('Surveyor Loading')">Surveyor Figure
          Loading Density</a>
        <a class="dropdown-item" (click)="addBl()">Bill of lading</a>
      </div>
    </ng-container>
    <button type="button" id="verify" *ngIf="!isVerified && hasRole('voyage-verifier')"
      class="btn btn-outline-secondary btn-light me-1" (click)="verify()"
      [disabled]="isVerified">Verify
    </button>
    <button type="button" id="removeVerify" *ngIf="isVerified && hasRole('admin-editor')"
      class="btn btn-outline-secondary btn-light me-1"
      (click)="removeVerification()"
      [disabled]="!isVerified">Remove verification
    </button>
    <button type="button" id="delete"
      *ngIf="mode ==='edit' && hasRole('vessels-voyages-editor')"
      class="btn btn-outline-secondary btn-light me-1"
      (click)="deleteDischarge()"
      [disabled]="isVerified">Delete
    </button>
    <button type="submit" id="submit" class="btn btn-primary me-1"
      (click)="userAction(); saveDischarge()"
      *ngIf="!isVerified && hasRole('vessels-voyages-editor')"
      [disabled]="!discharge.form.valid">Save
    </button>
  </div>
</form>
