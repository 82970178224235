import { Component, OnInit, ViewChild, NgZone, EventEmitter } from '@angular/core';
import { Organisation, OrganisationService } from '../organisation.service';
import { KeycloakService } from '../../authentication/keycloak.service';
import { DataTableDirective } from 'angular-datatables';
import { DataTablesService } from './../../shared/datatables/data-tables.service';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { PgTitleComponent } from '../../shared/components/title-bar/title-bar.component';

@Component({
  selector: 'app-organisations-list',
  templateUrl: './organisations-list.component.html',
})
export class OrganisationsListComponent implements OnInit, PgTitleComponent {
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;

  title = new EventEmitter<string>();
  organisations: Organisation[];
  temporarlyList: Organisation[];
  dtOptions = {};
  dtTrigger: Subject<any> = new Subject<any>();
  isDataTablesInitialised = false;

  parentOrganisation = '';

  constructor(private _organisatiosnService: OrganisationService,
              private _keycloakService: KeycloakService,
              private _router: Router,
              private _zone: NgZone,
              private _activatedRoute: ActivatedRoute) {
                window['OrganisationsListComponent'] = {component: this, zone: this._zone};
              }

  ngOnInit() {
    this.title.emit('Organisations List');
    const buttons = [{
      text: 'ایجاد سازمان جدید',
      action: () => window['OrganisationsListComponent'].component.addRecord()
      }];
    this.dtOptions = DataTablesService.getOptions(buttons);
    this._activatedRoute.paramMap.subscribe(p => {
      this._organisatiosnService.get().subscribe(organisations => {
        this.organisations = organisations;
        if (p.get('parentOrganisationId')) {
          this.temporarlyList = organisations.filter(organisation => organisation.parentOrganisation === p.get('parentOrganisationId'));
        } else {
          this.temporarlyList = organisations.filter(organisation => organisation.parentOrganisation === '');
        }
        this.datatableInit(true);
      });
    });
  }

  addRecord() {
    this._router.navigate(['/organisations/new/root']).then(_ => _);
  }

  datatableInit(success: boolean) {
    if (success) {
        if (!this.isDataTablesInitialised) {
          this.dtTrigger.next(false);
        } else {
          this.dtElement.dtInstance.then((dtInstance) => {
            dtInstance.destroy();
            this.dtTrigger.next(false);
          });
        }
        this.isDataTablesInitialised = true;
    }
  }

  organisationFilter(organisation: Organisation) {
    this._router.navigate(['organisations/list', organisation.name]).then();
  }

  edit(organisation: Organisation) {
    this._router.navigate(['organisations', 'edit', organisation.name]).then();
  }

  add(organisation: Organisation) {
    this._router.navigate(['organisations', 'new', organisation.name]).then();
  }
}
