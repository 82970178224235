import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable, Observer } from 'rxjs';
import { getKeycloakConfig, getLogoutUrl } from '../../environments/environment.service';

declare let Keycloak: any;

export interface UserData {
  fullName: string;
  email: string;
  loggedIn: boolean;
  authz: any;
  logoutUrl: string;
  username: string;
  roles: string[];
}

@Injectable({
  providedIn: 'root'
})
export class KeycloakService {
  static auth: UserData = {
    loggedIn: false,
    authz: {},
    logoutUrl: '',
    email: '',
    fullName: '',
    username: '',
    roles: []
  };

  static init(): Promise<any> {
    const keycloakAuth: any = new Keycloak(getKeycloakConfig());
    KeycloakService.auth.loggedIn = false;

    return new Promise((resolve, reject) => {
      keycloakAuth.init({ onLoad: 'login-required' })
        .then(() => {
          keycloakAuth.loadUserInfo().then((t: any) => {
            KeycloakService.auth.email = t.email;
            KeycloakService.auth.fullName = t.name;
            KeycloakService.auth.username = t.preferred_username;
          });
          KeycloakService.auth.loggedIn = true;
          KeycloakService.auth.authz = keycloakAuth;
          KeycloakService.auth.roles = keycloakAuth.tokenParsed.realm_access.roles;
          KeycloakService.auth.logoutUrl = getLogoutUrl();
          resolve(true);
        })
        .catch(() => {
          reject();
        });
    });
  }

  logout() {
    KeycloakService.auth.loggedIn = false;
    KeycloakService.auth.authz = null;
    KeycloakService.auth.roles = null;
    window.location.href = KeycloakService.auth.logoutUrl;
  }

  hasRole(roleName: string) {
    return KeycloakService.auth.roles.includes(roleName);
  }

  getToken(): Promise<string> {
    return new Promise((resolve) => {
      try {
        KeycloakService.auth.authz.updateToken(20).then(() => {
          resolve(KeycloakService.auth.authz.token as string);
        });
      } catch (error) {
        console.error(error);
      }
    });
  }
  addTokenToHeader(headersArg?: HttpHeaders): Observable<HttpHeaders> {
    return new Observable((observer: Observer<any>) => {
      let headers = headersArg;
      if (!headers) {
        headers = new HttpHeaders();
      }
      try {
        this.getToken().then(token => {
          headers = headers.set('Authorization', 'bearer ' + token);
          observer.next(headers);
          observer.complete();
        });
      } catch (error) {
        observer.error(error);
      }
    });
  }

  getUser(): UserData {
    return KeycloakService.auth;
  }

}

