import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { KeycloakService } from './app/authentication/keycloak.service';
import { isProduction } from './environments/environment.service';


if (isProduction()) {
  enableProdMode();
}

KeycloakService.init()
  .then(() => {
    platformBrowserDynamic().bootstrapModule(AppModule)
      .catch(err => console.log(err));
  });
