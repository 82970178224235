import { Injectable } from '@angular/core';
import { HttpWrapper } from '../shared/services';
import { Observable } from 'rxjs';
import { GeoCoordinate } from '../transportation/models/GeoCoordinate';
import { map } from 'rxjs/operators';
import { DataBaseAction } from '../transportation/vessels/vessels.service';

export class Organisation {
  name: string;
  internalName: string;
  address: string;
  manager: string;
  parentOrganisation: string;
  nameTranslations: {
    [key: string]: string;
  };
  properties: {
    [key: string]: boolean;
  };
  location: GeoCoordinate;
  timezone: string;
  actions: DataBaseAction[];
}

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {

  constructor(private _httpWrapper: HttpWrapper) { }

  get(): Observable<Organisation[]> {
    return this._httpWrapper.get<Organisation[]>('organisations');
  }

  getLatestUpdate(timestamp: number): Observable<Organisation[]> {
    return this._httpWrapper.get<Organisation[]>(`organisations/${timestamp}`);
  }

  getPorts(): Observable<Organisation[]> {
    return this.get().pipe(map(port => port.filter(o => (o.properties['isAnchorage'] === true ||
        o.properties['isPort'] === true)).sort((a, b) =>
      a.nameTranslations['en'].localeCompare(b.nameTranslations['en']))));
    }

  getSurveyers(): Observable<Organisation[]> {
    return this.get().pipe(map(
      surveyers => surveyers.filter(organisation => organisation.properties['isSurvey'] === true)));
  }

  getDomestics(): Observable<Organisation[]> {
    return this.get().pipe(map(
      surveyers => surveyers.filter(organisation => organisation.properties['domestic'] === true)));
  }

  getSupplier(): Observable<Organisation[]> {
    return this.get().pipe(map(
      supplier => supplier.filter(organisation => organisation.properties['isBunkerSupplier'] === true)));
  }

  getShippers(): Observable<Organisation[]> {
    return this.get().pipe(map(
      surveyers => surveyers.filter(organisation => organisation.properties['isShipper'] === true)));
  }

  getAgents(): Observable<Organisation[]> {
    return this.get().pipe(map(
      surveyers => surveyers.filter(organisation => organisation.properties['isShippingAgent'] === true)));
  }

  add(organisation: Organisation): Observable<Organisation> {
    return this._httpWrapper.post('organisations', organisation);
  }

  update(organisation: Organisation): Observable<Organisation> {
    return this._httpWrapper.put('organisations', organisation);
  }

  getDestinations(organisationId: string): Observable<Organisation[]> {
    return this._httpWrapper.get(`organisations/destinations/${organisationId}`);
  }

  getOrganisationTimezone(organisationId: string, organisations: Organisation[]) {
    return organisations.find(o => o.name === organisationId).timezone;
  }

  // getPorts(): Observable<Organisation[]> {
  //   return this.get().map(ports => ports.filter(o => (o.properties['isPort'] === true ||
  //     o.properties['isAnchorage'] === true)));
  //   }

  getLoadingsLocations(): Observable<Organisation[]> {
    return this.get().pipe(map(loadings => loadings.filter(o => (o.properties['isLoading'] === true))));
    }

  getDischargeLocations(): Observable<Organisation[]> {
    return this.get().pipe(map(dischages => dischages.filter(o => (o.properties['isUnloading'] === true))));
    }

  getSellers(): Observable<Organisation[]> {
    return this.get().pipe(map(selleres => selleres.filter(o => (o.properties['isSeller'] === true))));
    }

  getBuyers(): Observable<Organisation[]> {
    return this.get().pipe(map(buyers => buyers.filter(o => (o.properties['isBuyer'] === true))));
    }

  getTransporters(): Observable<Organisation[]> {
    return this.get().pipe(map(transporters => transporters.filter(o => (o.properties['isTransporter'] === true))));
    }

  getRootOrganisations(): Observable<Organisation[]> {
    return this.get().pipe(map(transporters => transporters
      .filter(o => ((o.properties['isLoading'] === false || !o.properties['isLoading'])
              && (o.properties['isUnloading'] === false || !o.properties['isUnloading'])
              && (o.properties['isAnchorage'] === false || !o.properties['isAnchorage'])
              && (o.properties['isPort'] === false || !o.properties['isPort'])
              && (o.properties['isSurvey'] === false || !o.properties['isSurvey'])
              && (o.properties['isConsignee'] === false || !o.properties['isConsignee'])
              ))));
    }

  getConsignee(): Observable<Organisation[]> {
    return this.get().pipe(map(consignee => consignee.filter(o => (o.properties['isConsignee'] === true))));
  }

  getOrganisationIdToOrganisationMap(): Observable<Map<string, Organisation>> {
    return this.get().pipe(map(orgs => new Map(orgs.map(o => [o.name, o]))));
  }

}
