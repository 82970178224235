<div>
  <div class="row">
    <div class="col-md-12">
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
        id="tblSearchCust"
        class="table table-sm table-striped  table-bordered ">
        <thead>
          <tr>
            <th>نام فارسی</th>
            <th>نام انگلیسی</th>
            <th>آدرس</th>
            <th>timezone</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let organisation of temporarlyList">
            <td>{{organisation.nameTranslations['fa']}}</td>
            <td>{{organisation.nameTranslations['en']}}</td>
            <td>
              {{organisation.address}}
            </td>
            <td>
              {{organisation.timezone}}
            </td>
            <td>
              <button id="filter" name="filter" type class="btn btn-link"
                (click)="organisationFilter(organisation)">
                <i class="fa fa-code-branch"></i>
              </button>
              <button id="add" name="add" type class="btn btn-link"
                (click)="add(organisation)">
                <i class="fa fa-plus"></i>
              </button>
              <button id="edit" name="edit" type class="btn btn-link"
                (click)="edit(organisation)">
                <i class="fa fa-edit"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
