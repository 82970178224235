
const hostname = window.location.hostname;
const parts = hostname.split('.');
const uatEnv = parts[0] && parts[0].includes('uat');
const domainName = `${parts[1]}.${parts[2]}`;
const ssoBaseUrl = `https://sso.${domainName}`;

export function getKeycloakConfig() {
  if (hostname !== 'localhost') {
    return {
      url: ssoBaseUrl,
      realm: 'hello',
      clientId: 'portal'
    };
  }
  return {
    url: `http://localhost:8080/`,
    realm: 'parsagroup',
    clientId: 'portal'
  };
}

export function getLogoutUrl() {
  if (hostname.includes('parsagroup')) {
    return `${ssoBaseUrl}/auth/realms/hello/protocol/openid-connect/logout?redirect_uri=https://${hostname}/`;
  }
  if (hostname !== 'localhost') {
    return `${ssoBaseUrl}/realms/hello/protocol/openid-connect/logout`;
  }
  else {
    return 'http://localhost:8080/realms/parsagroup/protocol/openid-connect/logout';
  }
}

export function getApiUrl() {
  if (hostname !== 'localhost') {
    const sub = uatEnv ? 'uat-api' : 'api';
    return `https://${sub}.${domainName}/api`;
  }
  else {
    return 'http://localhost:9000/api';
  }
}

export function isProduction() {
  return hostname !== 'localhost';
}


