import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { TruckReport, LPGDistService } from '../../lpg-dist.service';
import { DataTableDirective } from 'angular-datatables';

import { Truck, TrucksService } from 'src/app/transportation';
import { Subject, forkJoin } from 'rxjs';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import { DataTablesService } from 'src/app/shared/datatables/data-tables.service';
import { PgTitleComponent, PgDateRangeTitleComponent, PgToolbarComponent, PgDateRangeTitleEnum } from 'src/app/shared/components/title-bar/title-bar.component';
import { DateRange } from 'src/app/shared/components';
import { LpgDistToolbarComponent } from '../../lpg-dist-toolbar/lpg-dist-toolbar.component';
import * as moment from 'moment-jalaali';
import saveAs from 'file-saver';

@Component({
  selector: 'app-truck-reports',
  templateUrl: './trucks-reports.component.html',
})
export class TrucksReportsComponent implements OnInit, PgTitleComponent, PgDateRangeTitleComponent, PgToolbarComponent {
  title = new EventEmitter<string>();
  dateRangeSubTitle = new EventEmitter<[DateRange, PgDateRangeTitleEnum]>();
  toolbarComponentType = LpgDistToolbarComponent;
  toolbarComponent: LpgDistToolbarComponent;
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;
  loadings: TruckReport[];
  organisations: Organisation[];
  trucks: Truck[];
  dateRange: DateRange;
  dtOptions = {};
  dtTrigger: Subject<any> = new Subject<any>();
  isDataTablesInitialised = false;

  truckReport: TruckReport;


  constructor(
    private lpgDistService: LPGDistService,
    private trucksService: TrucksService,
    private organisationService: OrganisationService
  ){}

  ngOnInit() {
    const currentComponent = this;
    const buttons = [
      {
        text: 'خروجی اکسل',
        action: () => currentComponent.xlsxReport()
      }
    ];
    this.dtOptions = DataTablesService.getOptions(buttons);
    const end = moment().endOf('day').toDate();
    this.dateRange = {
      startDate:  moment(end).startOf('jMonth').toDate(),
      endDate: end
    };
    forkJoin([
      this.lpgDistService.getTrucksReport(this.dateRange.startDate.getTime(), this.dateRange.endDate.getTime()),
      this.trucksService.get(),
      this.organisationService.get()
    ]).subscribe(results => {
      this.loadings = results[0];
      this.organisations = results[2];
      this.trucks = results[1];
      this.initialiseDataTable();
      this.setToolbar();
    });
  }

  initialiseDataTable() {
    if (!this.isDataTablesInitialised) {
      this.dtTrigger.next(false);
    } else {
      this.dtElement.dtInstance.then((dtInstance) => {
        dtInstance.destroy();
        this.dtTrigger.next(false);
      });
    }
    this.isDataTablesInitialised = true;
  }

  setToolbar() {
    this.title.emit('Trucks loadings');
    this.dateRangeSubTitle.emit([this.dateRange, PgDateRangeTitleEnum.persian]);
    this.toolbarComponent.dateRange = {
      startDate: this.dateRange.startDate,
      endDate: this.dateRange.endDate
    };
    this.toolbarComponent.jalali = true;
    this.toolbarComponent.singleDate = false;
    this.toolbarComponent.itemsSelected.subscribe (t => {
      this.lpgDistService.getTrucksReport(t.dateRange.startDate.getTime(), t.dateRange.endDate.getTime()).subscribe(loadings => {
        this.loadings = loadings;
        this.dateRange = t.dateRange;
        this.dateRangeSubTitle.emit([t.dateRange, PgDateRangeTitleEnum.persian]);
        this.initialiseDataTable();
      });
    });
  }

  organisationNameFormatter(id: string) {
    if(id) {
      if(id.includes('parsagroup')) {
        return ` گروه پارسا ${this.organisations.find(o => o.name === id).nameTranslations['fa']}`;
      } else {
        return this.organisations.find(o => o.name === id).nameTranslations['fa'];
      }
    }
  }

  clickModal(truckReport: TruckReport) {
    this.truckReport = truckReport;
  }

  xlsxReport() {
    return this.lpgDistService
    .getTrucksReportExcel(this.dateRange.startDate.getTime(), this.dateRange.endDate.getTime())
    .subscribe(file => {
      saveAs(file.body, `All-trucks-loadings.xlsx`);
    });
  }
}


