<div class="row">
  <div class="col-md-12 ">
    <div>
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="lpgDistPayment"
      class="table table-sm table-striped  table-bordered " style="width:100%">
      <thead>
        <tr>
          <td>شرکت</td>
          <td>تاریخ</td>
          <td>مبدا</td>
          <td>شماره فیش</td>
          <td>میزان خریداری شده</td>
          <td>مبلغ فیش</td>
          <td *appHideIfNotInRole="['lpg-dist-editor']">ویرایش</td>
          <td *appHideIfNotInRole="['lpg-dist-editor']">حذف</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let pay of dataTable"  >
            <td>{{fotmatOrganisation(pay.companyId)}}</td>
            <td>{{pay.payTimestamp | persianDate}}</td>
            <td>{{fotmatOrganisation(pay.sourceOrganisationId)}}</td>
            <td>{{pay.paymentBillNo}}</td>
            <td>{{pay.totalWeight | number }}</td>
            <td>{{pay.wholePayment| number }}</td>
            <td *appHideIfNotInRole="['lpg-dist-editor']"><a id="edit" name="edit" type="submit"  class="btn-link" (click)="editPayment(pay)" >
              <i class="fa fa-pencil-square-o"></i>
            </a>
            </td>
            <td *appHideIfNotInRole="['lpg-dist-editor']"><a id="delete" name="delete" type="submit"  class="btn-link" (click)="deletePayment(pay)" >
              <i class="fa fa-trash-o"></i>
            </a>
            </td>
        </tr>
      </tbody>
      </table>
    </div>
  </div>
</div>