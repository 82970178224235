import { Component } from '@angular/core';
import { KeycloakService } from '../../../../../authentication/keycloak.service';
import { Router } from '@angular/router';
import { CrewChangeServices } from '../../crew-change.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-fleet-personnel-toolbar',
  templateUrl: './fleet-personnel-toolbar.component.html'
})
export class FleetPersonnelToolbarComponent {

  constructor(private _router: Router,
              private _crewChangeService: CrewChangeServices,
              private _keycloakService: KeycloakService) {
  }

  addCrew() {
    this._router.navigate(['vessels/crew-change/crew']).then();
  }

  addAppraisal() {
    this._router.navigate(['vessels/crew-change/appraisal']).then();
  }

  downloadLatestStatusCrew() {
    this._crewChangeService.getLastExcelReport().subscribe(file => {
      saveAs(file.body, `crew-latest-status.xlsx`);
    });
  }

  downloadAllCrewChanges() {
    this._crewChangeService.getExcelReport().subscribe(file => {
      saveAs(file.body, `all-crew-changes.xlsx`);
    });
  }

  downloadCrew() {
    this._crewChangeService.downloadCrew().subscribe(file => {
      saveAs(file.body, `all-crews.xlsx`);
    });
  }
}
