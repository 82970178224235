import { Component, EventEmitter, OnInit } from '@angular/core';
import { Crew, CrewAppraisal, CrewChangeServices } from '../crew-change.service';
import { KeycloakService } from '../../../../authentication/keycloak.service';
import { forkJoin } from 'rxjs';
import { Organisation, OrganisationService } from '../../../../organisation/organisation.service';
import { DocumentReference } from '../../../../documents/documents.service';
import { ActionType, DataBaseActionService } from '../../../../shared/services/database-action.service';
import { v4 as uuidv4 } from 'uuid';
import { MessageLevel, PgMessagesService } from '../../../../shared/services/pg-messages.service';
import {
  PgSubTitleComponent,
  PgTitleComponent
} from '../../../../shared/components/title-bar/title-bar.component';
import { Vessel, VesselsService } from '../../vessels.service';
import { ActivatedRoute } from '@angular/router';
import { LookupService } from 'src/app/shared/services/lookup.service';

@Component({
  selector: 'app-appraisal',
  templateUrl: './crew-appraisal.component.html',
  styleUrls: ['./crew-appraisal.component.css']
})
export class CrewAppraisalComponent implements OnInit, PgTitleComponent, PgSubTitleComponent {

  title = new EventEmitter<string>();
  subTitle = new EventEmitter<string>();
  crews: Crew[];
  selectedCrew: Crew;
  master: Crew;
  assessor: Crew;
  crewId: string;
  rank: string;
  ranks: string[];

  crewAppraisal: CrewAppraisal;

  _appraisalDate: string;
  document: DocumentReference;

  technicalManagements: Organisation[];
  _selectedTechnicalManagement: Organisation;

  get selectedTechnicalManagement() {
    return this._selectedTechnicalManagement;
  }

  set selectedTechnicalManagement(value: Organisation) {
    if (value) {
      this._selectedTechnicalManagement = value;
    }
  }

  get appraisalDate() {
    return this._appraisalDate;
  }

  set appraisalDate(value: string) {
    if(value) {
      this._appraisalDate = value;
    }
  }

  vessels: Vessel[];
  selectedVessel: Vessel;

  constructor(private _crewChangeService: CrewChangeServices,
              private _vesselsService: VesselsService,
              private _organisationService: OrganisationService,
              private _keycloakService: KeycloakService,
              private _actionService: DataBaseActionService,
              private _pgMessagesService: PgMessagesService,
              private _activateRoute: ActivatedRoute,
            private _lookupService: LookupService) { }

  ngOnInit(): void {
    this._activateRoute.paramMap.subscribe(p => {
      this.crewId = p.get('id');
      forkJoin([
        this._organisationService.get(),
        this._crewChangeService.getAllCrew(),
        this._lookupService.get('ranks'),
        this._crewChangeService.getOneCrewById(this.crewId),
        this._vesselsService.getActiveVessels(),
      ]).subscribe((results) => {
        this.technicalManagements = results[0].filter(s => s.properties['technical'] === true);
        this.crews = results[1];
        this.ranks = results[2].items.map(i => i[0]).filter(r => results[3].crewChanges.map(c => c.rank).includes(r));
        this.vessels = results[4].filter(v => results[3].crewChanges.map(c => c.vesselIMO).includes(v.IMO));
        this.selectedCrew = results[3];
        this.title.emit(`${this.selectedCrew.firstName} ${this.selectedCrew.lastName} Officer Appraisal`);
      });
    });
  }


  crewFormatter(index: Crew) {
    return `${index.firstName} ${index.lastName} ${index.passportDetails.passportNumber}`;
  }

  rankFormatter(data: any) {
    return data;
  }

  selectCrew(selectedCrew: Crew) {
    if (selectedCrew) {
      this.selectedCrew = selectedCrew;
    }
  }

  hasRole(roleName: string) {
    return this._keycloakService.hasRole(roleName);
  }

  add() {
    this._crewChangeService.getCrewAppraisalItems(this.document.id).subscribe(items => {
      this.crewAppraisal = {
        id: uuidv4(),
        crewId: this.selectedCrew.id,
        technicalManagementOrganisationId: this.selectedTechnicalManagement.name,
        vesselName: this.selectedVessel.name.name,
        vesselIMO: this.selectedVessel.IMO,
        date: this.appraisalDate,
        documentId: this.document.id,
        items,
        masterId: this.master.id,
        assessorId: this.assessor.id
      };
      this.selectedCrew.actions.push(this._actionService.userAction(ActionType.Update));
      this.selectedCrew.appraisals.push(this.crewAppraisal);
      this._crewChangeService.updateCrew(this.selectedCrew).subscribe({
        next: () => {
          this._pgMessagesService.publishMessage({
            level: MessageLevel.Info,
            topic: 'Crew Appraisal',
            message: `Appraisal for ${this.selectedCrew.firstName} ${this.selectedCrew.lastName} added.`
          });
        },
        error: () => {
          this._pgMessagesService.publishMessage({
            level: MessageLevel.Error,
            topic: 'Crew Appraisal',
            message: `Could not add appraisal for ${this.selectedCrew.firstName} ${this.selectedCrew.lastName}.`
          });
        }
      });
    });
  }

  documentFileAdded(documentReference: DocumentReference) {
    this.document = documentReference;
  }
}
