<div class="row">
  <div class="col-md-6">
    <div class="card card-primary">
      <form #voyageEntry="ngForm" class="form-horizontal"
        (ngSubmit)="saveVoyage()">
        <div class="card-body">
          <div class="form-group row">
            <label for="selectedVessel"
              class="col-form-label col-md-2 ">Vessel</label>
            <div class="col-md-4">
              <select id="selectedVessel" name="selectedVessel"
                class="form-control"
                [(ngModel)]="selectedVessel" required>
                <option *ngFor="let vessel of vessels" [ngValue]="vessel">
                  {{vessel.name.name}}
                </option>
              </select>
            </div>
            <label for="vesselName" class="col-form-label col-md-2 "
              *ngIf="selectedVessel !== undefined && selectedVessel.previousNames.length > 0 ">Old
              Name</label>
            <div class="col-md-4"
              *ngIf="selectedVessel !== undefined && selectedVessel.previousNames.length > 0 ">
              <select id="vesselName" name="vesselName" class="form-control"
                [(ngModel)]="vesselOldName">
                <option> -- select an option -- </option>
                <option *ngFor="let vesselName of selectedVessel.previousNames"
                  [ngValue]="vesselName">
                  {{vesselName.name}}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label for="shipVoyageNumber" class="col-form-label col-md-2 ">Ship
              VN</label>
            <div class="col-md-4">
              <input id="shipVoyageNumber" name="shipVoyageNumber" type="number"
                [(ngModel)]="selectedVoyage.shipVoyageNo" class="form-control"
                required>
            </div>
            <label for="agentVoyageNumber"
              class="col-form-label col-md-2 ">Agent VN</label>
            <div class="col-md-4">
              <input id="agentVoyageNumber" name="agentVoyageNumber"
                type="text" [(ngModel)]="selectedVoyage.agentVoyageNo"
                class="form-control">
            </div>
          </div>
          <div class="btn-group" role="group"
            aria-label="Button group with nested dropdown">
            <button type="submit" id="submit" class="btn btn-primary"
              [disabled]="!voyageEntry.form.valid">Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
