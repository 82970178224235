<div class="row" *ngIf="crew">
  <div class="col-md-3">
    <div class="card mb-4">
      <div class="card-body ">
        <div class="row text-center">
          <app-img-viewer
          [width]="'140px'"
          [height]="'150px'"
          [documentId]="crew?.photoDocumentId"
          [alt]="crew?.firstName"
          [class]="'rounded-circle'"
          />
        </div>
        <div class="row text-center">
          <h5 class="my-3 text-capitalize">{{crew.firstName}} {{crew.middleName}} {{crew.lastName}}</h5>
          <p>Last position {{getLastCrewChange(crew.crewChanges)?.rank}} {{getLastCrewChange(crew.crewChanges)?.vesselName}}</p>
          <div class="small-ratings">
            <i *ngFor="let r of rate[0]" class="fa fa-star rating-color"></i>
            <i *ngFor="let r of rate[1]" class="fa fa-star"></i>
            {{rate[2]}} of {{crew.appraisals.length}} appraisal(s)
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0"><i class="fa fa-child" aria-hidden="true"></i>
                Age</h6> <span class="text-secondary">{{calculateAge()}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0"><i class="fa fa-phone" aria-hidden="true"></i>
                Contact No.</h6> <span class="text-secondary">{{crew.contactNumber}}</span>
            </li>          
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap" *ngIf="nationality">
              <h6 class="mb-0"><i class="fa fa-flag" aria-hidden="true"></i>
                Nationality</h6> <span class="text-secondary">{{nationalityFormatter(nationality)}}</span>
            </li>          
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0"><i class="fas" [ngClass]="{'fa-user-clock' : getStatus() !== 'On-board', 'fa-user-check' : getStatus() === 'On-board'}" aria-hidden="true"></i>
                Status</h6> <span class="text-secondary">{{getStatus()}}</span>
            </li>          
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">
                <i class="fa fa-ship"></i>
                Seaman
                <a (click)="downLoadDocumnet(crew.seamanDetails?.seamanFileId)" *ngIf ="crew.seamanDetails?.seamanFileId">
                  <i class="fa fa-download" aria-hidden="true"></i>
                </a>
              </h6>
              <span class="text-secondary">{{crew.seamanDetails?.seamanNumber}} {{crew.seamanDetails?.seamanExpirationDate}}
              </span>
            </li>         
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0"><i class="fas fa-passport"></i>
                Passport
                <a (click)="downLoadDocumnet(crew.passportDetails?.passportFileId)" *ngIf ="crew.passportDetails?.passportFileId">
                  <i class="fa fa-download" aria-hidden="true"></i>
                </a>
              </h6>
                <span class="text-secondary">{{crew.passportDetails?.passportNumber}} {{crew.passportDetails?.passportExpirationDate}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0"><i class="fas fa-passport"></i>
                CV
                <a (click)="downLoadDocumnet(crew.cvDocumentId)" *ngIf ="crew.cvDocumentId">
                  <i class="fa fa-download" aria-hidden="true"></i>
                </a>
              </h6>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-9 h-100" *ngIf="crew && crew.crewChanges.length > 0">
    <div class="card">
      <div class="card-header">
        Crew Changes
      </div>
      <div class="card-body ">
        <table class="table table-sm table-striped table-bordered">
          <thead>
          <tr>
            <th>Rank</th>
            <th>Vessel Name</th>
            <th>Voyage Number</th>
            <th>Incoming</th>
            <th>On Board</th>
            <th>Off Board</th>
            <th>Outgoing</th>
            <th>Months Onboard</th>
            <th>Travel (Days)</th>
            <th>Cancelled</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let crewChange of crew.crewChanges; let i = index;">
            <td>{{crewChange.rank}}</td>
            <td>{{crewChange.vesselName}}</td>
            <td>{{crewChange.voyageNumber}}</td>
            <td>
              {{planPlace(crewChange, 'Incoming Flight')}}
              <br/>
              {{planTime(crewChange, 'Incoming Flight')}}
            </td>
            <td>
              {{planPlace(crewChange, 'On Board')}}
              <br/>
              {{planTime(crewChange, 'On Board')}}
            </td>
            <td>
              {{planPlace(crewChange, 'Off Board')}}
              <br/>
              {{planTime(crewChange, 'Off Board')}}
            </td>
            <td>
              {{planPlace(crewChange, 'Outgoing Flight')}}
              <br/>
              {{planTime(crewChange, 'Outgoing Flight')}}
            </td>
            <td>{{onboardTimeMonthsDays(crewChange)}}</td>
            <td>{{travelTimeDays(crewChange)}}</td>
            <td><i *ngIf="crewChange.isCancelled" class="fa fa-times" aria-hidden="true"></i></td>
            <td><a *ngIf="crew" [routerLink]="['/vessels/crew-change/crew-change-data-entry/', crew.id, crewChange.id ]"><i class="fa fa-edit"></i></a></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <app-timeline [timelineHeader]="'Crew Changes Timeline'" [timelineItems]="crewChangeTimeline"></app-timeline>
  </div>
</div>