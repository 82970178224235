<div class="row">
  <div class="col-md-12 col-xs-12">
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" 
      id="trucksList"
             class="table table-sm table-striped table-bordered text-center" style="width:100%">
          <thead>
          <tr>
              <th>شماره پلاک</th>
              <th>نام شرکت</th>
              <th>تعداد سرویس</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let truck of loadings">
              <td>{{truck.truckNumber}}</td>
              <td>{{truck?.manager}}</td>
              <td><a class="btn btn-link" data-bs-toggle="modal" data-bs-target="#loadingModal" (click)="clickModal(truck)">{{truck.count}}</a></td>
          </tr>
          </tbody>
      </table>
  </div >
  <div class="modal fade" id="loadingModal" tabindex="-1" aria-labelledby="loadingModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="loadingModalLabel">{{truckReport?.truckNumber}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div *ngFor="let loadings of truckReport?.groupedLoadings" class="card">
            <table class="table table-sm table-striped table-bordered text-center">
              <thead>
                <tr>
                  <td>نام شرکت</td>
                  <td>تعداد</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{organisationNameFormatter(loadings.companyId)}}</td>
                  <td>{{loadings.count}}</td>
                </tr>
              </tbody>
            </table>
            <div>
              <table class="table table-sm table-striped table-bordered text-center">
                <thead>
                  <tr>
                    <td>تاریخ</td>
                    <td>مبدا</td>
                    <td>نام راننده</td>
                    <td>مقصد</td>
                    <td>وزن</td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let loading of loadings.loadings">
                    <td>{{loading.loadTimestamp | persianDate}}</td>
                    <td>{{organisationNameFormatter(loading.sourceOrganisationId)}}</td>
                    <td>{{loading.driverName}}</td>
                    <td>{{organisationNameFormatter(loading.destinationOrganisationId)}}</td>
                    <td>{{loading.loadingWeight | number}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>