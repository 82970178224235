import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { PgToolbarComponent } from '../../../../shared/components/title-bar/title-bar.component';
import { FleetPersonnelToolbarComponent } from './fleet-personnel-toolbar/fleet-personnel-toolbar.component';
import { Crew, CrewChange, CrewChangeServices } from '../crew-change.service';
import moment from 'moment';
import { forkJoin } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';


@Component({
  selector: 'app-fleet-management',
  templateUrl: './fleet-personnel-management.component.html'
})
export class FleetPersonnelManagementComponent implements OnInit, PgToolbarComponent {
  @ViewChild(DataTableDirective, {static: true})
  chiefsDtElement: DataTableDirective;

  title: EventEmitter<string> = new EventEmitter();
  toolbarComponentType = FleetPersonnelToolbarComponent;
  toolbarComponent: FleetPersonnelToolbarComponent;

  numberOfMastersOnboard: number = null;
  numberOfCrewOnBoard: number = null;
  numberOfOffSignersInLast30Days: number = null;
  numberOfOnSignersInLast30Days: number = null;
  crewChanges: CrewChange[];
  onBoards: Crew[];
  crews: Crew[];
  organisations: Organisation[];



  constructor(
    private _crewChangeService: CrewChangeServices,
    private _organisationService: OrganisationService
    ) { }

  ngOnInit(): void {
    this.title.emit('Fleet personnel');
    this.fillData();
  }


  getOnBoards() {
    return  this.crews.map(c => {
        const crewChanges = c.crewChanges.filter(c => {
        const plans = c.item?.map(s => s.plan);
        if (plans && plans.length > 0) {
          return (plans.includes('On Board') && !plans.includes('Off Board') && c.isDeleted !== true && c.isCancelled !== true);
        }
        return false;
      });
      return {
        ...c,
        crewChanges
      };
    }).filter(c => c.crewChanges.length > 0);
  }

  getNumberOfOffSignersInLast30Days() {
    const period = moment().subtract(30, 'days').unix() * 1000;
    return this.crewChanges.filter(c => {
      if (c.item && c.item.length > 0) {
        const lastAction  = c.item.sort((a, b) => b.time - a.time)[0];
        return lastAction.plan === 'Outgoing Flight' && lastAction.time > period;
      }
      return false;
    }).length;
  }

  getNumberOfOnSignersInLast30Days() {
    const period = moment().subtract(30, 'days').unix() * 1000;
    return this.crewChanges.filter(c => {
      if (c.item && c.item.length > 0) {
        const lastAction = c.item.sort((a, b) => b.time - a.time)[0];
        return lastAction.plan === 'On Board' && lastAction.time > period;
      }
      return false;
    }).length;

  }

  fillData() {
    forkJoin([
      this._crewChangeService.getAllCrew(),
      this._organisationService.get()
    ]).subscribe((value) => {
        this.crews = value[0];
        this.crewChanges = this.crews.flatMap(c => c.crewChanges);
        this.organisations = value[1];
        this.onBoards = this.getOnBoards();
        this.numberOfCrewOnBoard = this.onBoards.length;
        this.numberOfMastersOnboard = this.onBoards
          .filter(c => c.crewChanges.filter(c => c.rank === 'Master').length > 0).length;
        this.numberOfOffSignersInLast30Days = this.getNumberOfOffSignersInLast30Days();
        this.numberOfOnSignersInLast30Days = this.getNumberOfOnSignersInLast30Days();
      }
    );
  }

}
