import { Component, Input, OnInit } from '@angular/core';
import { DocumentService } from '../../../documents/documents.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
})
export class PdfViewerComponent implements OnInit {
  src: any;
  numberOfPages: number;
  pageNumber = 1;
  zoomLevel = 1;
  private _fileId: string;
  _width: any;
  _height: any;


  constructor(private _documentsService: DocumentService) { }

  @Input() set fileId(value: string) {
    this._fileId = value;
    this._documentsService.download(value).subscribe(b => {
      const t = new Blob([b.body], { type: b.body.type });
      const fileReader = new FileReader();
      fileReader.onload = () => {
        this.src = new Uint8Array(fileReader.result as ArrayBuffer);
      };
      fileReader.readAsArrayBuffer(t);
    });
  }

  @Input() set width(value: any) {
    this._width = value;
  }

  @Input() set height(value: any) {
    this._height = value;
  }

  ngOnInit() {
    this.pageNumber = 1;
  }

  pdfRendered($event: any) {
    this.numberOfPages = $event.numPages;
  }

  download() {
    this._documentsService.download(this._fileId).subscribe(f => {
      saveAs(f.body, `${this._fileId}.pdf`);
    });
  }
}
