<div class="row" *ngIf="bunkeringDeliveryNote">
  <div class="col-md-6">
    <fieldset style="border: 0;"
      [disabled]="!hasRole('vessels-delivery-note-editor')">
      <form #BunkeringDeliveryNote="ngForm" class="form-horizontal">
        <div class="card shadow-sm">
          <div class="card-body">
            <div class="form-group row">
              <label for="number" class="col-form-label col-md-4 ">BDN
                NO</label>
              <div class="col-md-8">
                <input
                  type="text"
                  class="form-control"
                  name="number"
                  id="number"
                  tabindex="1"
                  [disabled]="disable"
                  [(ngModel)]="bunkeringDeliveryNote.bdnNumber" />
              </div>
            </div>
            <div class="form-group row">
              <label for="vessel"
                class="col-form-label col-md-4 ">Vessel</label>
              <div class="col-md-8" *ngIf="!disable">
                <select
                  id="vessel"
                  name="vessel"
                  class="form-control"
                  tabindex="2"
                  [(ngModel)]="selectedVessel"
                  required>
                  <option *ngFor="let vessel of vessels" [ngValue]="vessel">
                    {{ vessel.name.name }}
                  </option>
                </select>
              </div>
              <div class="col-md-8" *ngIf="disable">
                <input
                  type="string"
                  class="form-control"
                  name="vessel1"
                  id="vessel1"
                  [disabled]="disable"
                  [(ngModel)]="bunkeringDeliveryNote.vesselName" />
              </div>
            </div>
            <div class="form-group row ">
              <label for="location"
                class="col-form-label col-md-4 ">Location</label>
              <div class="col-md-8">
                <app-organisation-selector
                  id="location"
                  class="pg-component"
                  [(organisation)]="selectedPortOrganisation"
                  [organisationLanguage]="'en'"
                  name="location"
                  [organisations]="ports"
                  [disabled]="disable"
                  tabindex="7"
                  required>
                </app-organisation-selector>
              </div>
            </div>
            <div class="form-group row">
              <label for="date" class="col-form-label col-md-4 ">Date</label>
              <div class="col-md-8">
                <app-date-range
                  id="date"
                  [singleDateSelector]="true"
                  [dateRange]="bdnDate"
                  [timePicker]="true"
                  [localeDateFormat]="'YYYY-MM-DD HH:mm'"
                  (dateRangeChange)="date($event)"
                  [disabled]="disable || !locationSelected">
                </app-date-range>
              </div>
            </div>
            <div class="form-group row">
              <label for="product"
                class="col-form-label col-md-4 ">Product</label>
              <div class="col-md-8">
                <select
                  class="form-control"
                  name="Product"
                  id="Product"
                  [disabled]="disable"
                  tabindex="4"
                  [(ngModel)]="bunkeringDeliveryNote.product"
                  required>
                  <option
                    *ngFor="let product of productName"
                    value="{{ product }}">
                    {{ product }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label for="quantity"
                class="col-form-label col-md-4 ">Quantity</label>
              <div class="col-md-8">
                <input
                  type="number"
                  class="form-control"
                  name="quantity"
                  id="quantity"
                  [(ngModel)]="bunkeringDeliveryNote.quantity"
                  tabindex="5"
                  required />
              </div>
            </div>
            <div class="form-group row ">
              <label for="supplier"
                class="col-form-label col-md-4 ">Supplier</label>
              <div class="col-md-8">
                <app-organisation-selector
                  id="supplier"
                  class="pg-component"
                  [(organisation)]="selectedSupplierOrganisation"
                  [organisationLanguage]="'en'"
                  name="supplier"
                  [organisations]="supplier"
                  tabindex="6"
                  required>
                </app-organisation-selector>
              </div>
            </div>
            <div class="form-group row">
              <label
                for="bdnFile"
                class="col-form-label col-md-4 ">File</label>
              <div class="col-md-8">
                <app-chunk-file-uploader
                  [accept]="'.pdf'"
                  id="bdnFile"
                  name="bdnFile"
                  (filesChanged)="uploadBDNFile($event)"
                  [required]="true"
                  tabindex="9">
                </app-chunk-file-uploader>
              </div>
            </div>
            <div class="form-group row">
              <label for="Viscosity"
                class="col-form-label col-md-4 ">Viscosity</label>
              <div class="col-md-8">
                <input
                  type="number"
                  class="form-control"
                  name="Viscosity"
                  id="Viscosity"
                  [(ngModel)]="bunkeringDeliveryNote?.spec.viscosity" />
              </div>
            </div>
            <div class="form-group row">
              <label for="Density"
                class="col-form-label col-md-4 ">Density</label>
              <div class="col-md-8">
                <input
                  type="number"
                  class="form-control"
                  name="Density"
                  id="Density"
                  tabindex="12"
                  [(ngModel)]="bunkeringDeliveryNote?.spec.density" />
              </div>
            </div>
            <div class="form-group row">
              <label for="Water" class="col-form-label col-md-4 ">Water</label>
              <div class="col-md-8">
                <input
                  type="number"
                  class="form-control"
                  name="Water"
                  id="Water"
                  tabindex="13"
                  [(ngModel)]="bunkeringDeliveryNote?.spec.water" />
              </div>
            </div>
            <div class="form-group row">
              <label for="Sulphur"
                class="col-form-label col-md-4 ">Sulphur</label>
              <div class="col-md-8">
                <input
                  type="number"
                  class="form-control"
                  name="Sulphur"
                  id="Sulphur"
                  tabindex="14"
                  [(ngModel)]="bunkeringDeliveryNote?.spec.sulphur" />
              </div>
            </div>
            <div class="form-group row">
              <label for="Flash Point" class="col-form-label col-md-4 ">Flash
                Point</label>
              <div class="col-md-8">
                <input
                  type="number"
                  class="form-control"
                  name="Flash Point"
                  id="Flash Point"
                  tabindex="15"
                  [(ngModel)]="bunkeringDeliveryNote?.spec.flashPoint" />
              </div>
            </div>
            <div class="form-group row">
              <label for="priorRob" class="col-form-label col-md-4 ">Prior
                Rob</label>
              <div class="col-md-8">
                <input
                  type="number"
                  class="form-control"
                  name="priorRob"
                  id="priorRob"
                  [(ngModel)]="bunkeringDeliveryNote.priorRob" />
              </div>
            </div>
            <div class="form-group row">
              <label for="date" class="col-form-label col-md-4 ">Along
                side</label>
              <div class="col-md-8">
                <app-date-range
                  id="alongSide"
                  [singleDateSelector]="true"
                  [dateRange]="alongSide"
                  [timePicker]="true"
                  [localeDateFormat]="'YYYY-MM-DD HH:mm'"
                  (dateRangeChange)="alongSideSetter($event)"
                  [disabled]="disable || !locationSelected">
                </app-date-range>
              </div>
            </div>
            <div class="form-group row">
              <label for="hoseConnected"
                class="col-form-label col-md-4 ">HoseConnected</label>
              <div class="col-md-8">
                <app-date-range
                  id="hoseConnected"
                  name="hoseConnected"
                  [dateRange]="hoseConnected"
                  (dateRangeChange)="hoseConnectedSetter($event)"
                  [timePicker]="true"
                  [localeDateFormat]="'YYYY-MM-DD HH:mm'"
                  [singleDateSelector]="true"
                  [disabled]="disable || !locationSelected || !alongSideSetted">
                </app-date-range>
              </div>
            </div>
            <div class="form-group row">
              <label for="commencedPumping"
                class="col-form-label col-md-4 ">Commenced Pumping</label>
              <div class="col-md-8">
                <app-date-range
                  id="commencedPumping"
                  name="commencedPumping"
                  [timePicker]="true"
                  [localeDateFormat]="'YYYY-MM-DD HH:mm'"
                  [dateRange]="commencedPumping"
                  (dateRangeChange)="commencedPumpingSetter($event)"
                  [singleDateSelector]="true"
                  [disabled]="disable || !locationSelected || !hoseConnectedSetted">
                </app-date-range>
              </div>
            </div>
            <div class="form-group row">
              <label for="alongSide" class="col-form-label col-md-4 ">Completed
                Pumping</label>
              <div class="col-md-8">
                <app-date-range
                  id="completedPumping"
                  name="completedPumping"
                  [dateRange]="completedPumping"
                  (dateRangeChange)="completedPumpingSetter($event)"
                  [timePicker]="true"
                  [localeDateFormat]="'YYYY-MM-DD HH:mm'"
                  [singleDateSelector]="true"
                  [disabled]="disable || !locationSelected || !commencedPumpingSetted">
                </app-date-range>
              </div>
            </div>
            <div class="form-group row">
              <label for="alongSide" class="col-form-label col-md-4 ">Hose
                Disconnected</label>
              <div class="col-md-8">
                <app-date-range
                  id="hoseDisconnected"
                  name="hoseDisconnected"
                  [(ngModel)]="hoseDisconnected"
                  [dateRange]="hoseDisconnected"
                  (dateRangeChange)="hoseDisconnectedSetter($event)"
                  [timePicker]="true"
                  [localeDateFormat]="'YYYY-MM-DD HH:mm'"
                  [singleDateSelector]="true"
                  [disabled]="disable || !locationSelected || !bunkeringDeliveryNote.sof.completedPumping">
                </app-date-range>
              </div>
            </div>
            <div class="form-group row">
              <label
                for="fuelAnalysis"
                class="col-form-label col-md-4 ">Fuel Analysis</label>
              <div class="col-md-8">
                <app-chunk-file-uploader
                  [accept]="'.pdf'"
                  name="fuelAnalysis"
                  id="fuelAnalysis"
                  (filesChanged)="uploadFuelAnalysis($event)"
                  tabindex="10">
                </app-chunk-file-uploader>
              </div>
            </div>
            <div class="form-group row pull-right">
              <div>
                <button type="submit"
                  class="btn btn-primary me-1"
                  [disabled]="!BunkeringDeliveryNote.form.valid && !valid"
                  id="save"
                  (click)="save()">
                  Save
                </button>
                <button type="button"
                  id="delete"
                  class="btn btn-outline-secondary btn-light"
                  *ngIf="disable"
                  (click)="remove()">
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card shadow-sm">
          <div class="card-header">
            <h3 class="card-title">Fuel Analysis</h3>
          </div>
          <div class="card-body">
            <div class="col-md-12">
              <table
                class="table table-sm table-striped table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Download</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let object of bunkeringDeliveryNote.fuelFileIds ;let in=index">
                    <td>{{in + 1}}</td>
                    <td>
                      <a (click)="downloadFile(object)"> <i
                          class="fa fa-download" aria-hidden="true"></i></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </form>
    </fieldset>
  </div>
  <div class="col-md-6"
    *ngIf="bunkeringDeliveryNote && bunkeringDeliveryNote.fileId">
    <app-pdf-viewer [fileId]="bunkeringDeliveryNote.fileId"></app-pdf-viewer>
  </div>
</div>
