import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { LPGDistData, LPGDistService, ToolbarItem } from '../../../lpg-dist.service';
import { Organisation, OrganisationService } from '../../../../organisation/organisation.service';
import * as momentJ from 'moment-jalaali';
import { saveAs } from 'file-saver';
import { ActivatedRoute, Router } from '@angular/router';
import { DateRange } from '../../../../shared/components';
import { KeycloakService } from 'src/app/authentication/keycloak.service';
import { DataTableDirective } from 'angular-datatables';
import { forkJoin, Subject} from 'rxjs';
import { DataTablesService } from '../../../../shared/datatables/data-tables.service';
import { LpgDistToolbarComponent } from 'src/app/lpg-dist/lpg-dist-toolbar/lpg-dist-toolbar.component';
import { UserLocalStoreService } from 'src/app/shared/services/user-local-store.service';
import {
  PgDateRangeTitleComponent, PgDateRangeTitleEnum, PgTitleComponent,
  PgToolbarComponent
} from '../../../../shared/components/title-bar/title-bar.component';
import { Config } from 'datatables.net';
import { DateTimeHelpers } from 'src/app/shared/helpers/date-time.helpers';

interface LpgDistDetailComponentSettings {
  companyId: string;
}

@Component({
  selector: 'app-lpg-dist-detail',
  templateUrl: './lpg-dist-detail.component.html',
})
export class LpgDistDetailComponent implements  OnInit,
  PgTitleComponent, PgDateRangeTitleComponent, PgToolbarComponent {
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;

  title = new EventEmitter<string>();
  dateRangeSubTitle = new EventEmitter<[DateRange, PgDateRangeTitleEnum]>();
  toolbarComponentType = LpgDistToolbarComponent;
  toolbarComponent: LpgDistToolbarComponent;
  search: string;
  dateRange: DateRange;
  organisations: Organisation[];
  _organisationsCache: { [key: string]: Organisation} = {};
  selectedOrganisation: Organisation;

  dtOptions: Config = {};
  dtTrigger: Subject<any> = new Subject<any>();
  isDataTablesInitialised = false;
  _settings: LpgDistDetailComponentSettings;

  constructor(private _lpgDistService: LPGDistService,
              private _activateRoute: ActivatedRoute,
              private _keycloakService: KeycloakService,
              private _router: Router,
              private _organisationService: OrganisationService,
              private _userLocalStorage: UserLocalStoreService) { }

  ngOnInit() {
    const currentComponent = this;
    const buttons = [
        {
          text: 'خروجی اکسل',
          action: () => currentComponent.xlsxReport()
        }
      ];

    this.dtOptions = DataTablesService.getOptions(buttons);
    this.getParams();
  }

  columnsFormatter() {
    return  [
        {
          title:'تاریخ',
          data: 'loadTimestamp',
          render:  (data) => DateTimeHelpers.formatPersianLocalDateTime(data)
        },
        {
          title:'میدا',
          data: 'source',
        },
        {
          title:'شماره بارنامه',
          data: 'billOfLadingNumber',
          type: 'number'
        },
        {
          title:'نام راننده',
          data: 'driverName',
        },
        {
          title:'شماره کشنده',
          data: 'truckNumber',
        },
        {
          title:'شماره تانکر',
          data: 'tankNumber',
        },
        {
          title:'شرکت حمل کننده',
          data: 'transporterOrganisationId',
          render: (data) => this.findOrganisationName(data)
        },
        {
          title:'مقصد ابتدایی',
          data: 'primaryDestination',
          render: (data) => this.findOrganisationName(data)
        },
        {
          title:'مقصد',
          data: 'destination',
        },
        {
          title:'وزن',
          data: 'loadingWeight',
          render: (data) => parseInt(data).toLocaleString('en-US'),
          type: 'number'
        },
        {
          title:'تخلیه',
          data: 'unload',
          render: (data) => this.unloadCheckbox(data)
        },
        {
          title:'تایید',
          data: 'verify',
          render: (data) => this.verifydCheckbox(data)
        },
        ...this.hasRole('lpg-dist-editor') ? [{
          title:'ویرایش',
          data: 'verify',
          render: (data, type, row) => this.editButton(row)
        }]: [],
        ...this.hasRole('lpg-dist-editor') ? [{
          title:'حذف',
          data: 'verify',
          render: () => this.deleteButton(),
        }] : [],
      ];
  }

  editButton(row) {
    const style = (row.modify && (row.modify.updatedOn !== row.modify.insertedOn)) ? 'alert alert-warning' : '';
    return `<button id="edit" name="edit" type="submit" class="btn btn-link ${style} ">
              <i class="fa fa-pencil-square-o"></i>
            </button>`;
  }

  deleteButton() {
    return `<button id="delete" name="delete" type="submit"  class="btn btn-link">
                <i class="fa fa-trash-o"></i>
              </button>`;
  }

  unloadCheckbox(data) {
    const checked = (data) ? 'checked' : '';
    const disabled = (!this.hasRole('lpg-dist-editor')) ? 'disabled' : '';
    return `<input  id="firstCheck" name="firstCheck" type="checkbox" class="btn-link" ${checked} ${disabled}>`;
  }

  verifydCheckbox(data) {
    const checked = (data) ? 'checked' : '';
    const disabled = (!this.hasRole('lpg-dist-editor')) ? 'disabled' : '';
    return `<input id="confirm" name="confirm" type="checkbox" class="btn-link" ${checked} ${disabled}>`;
  }


  setToolbar() {
    this.toolbarComponent.dateRange = {
      startDate: this.dateRange.startDate,
      endDate: this.dateRange.endDate
    };
    this.toolbarComponent.jalali = true;
    this.toolbarComponent.singleDate = false;
    this.toolbarComponent.visibleOrganisation = true;
    this.toolbarComponent.organisations = this.organisations
    .filter(o =>  ['domestic'].filter(s => o.properties[s]).length > 0);
    this.toolbarComponent.selectedOrganisation =  this.selectedOrganisation;
    this.toolbarComponent.itemsSelected.subscribe (t => {
      this.refresh(t);
    });
  }

  getParams() {
    this._activateRoute.queryParams.subscribe(p => {
      this._settings = this._userLocalStorage.get<LpgDistDetailComponentSettings>('LpgDistDetailComponent');
      const company = (p['company']) ? p['company'] : (this._settings) ?  this._settings.companyId : 'iran.parsagroup.orsagas';
      const endDate =  (p['endTime']) ? +p['endTime'] : momentJ().endOf('day').toDate().getTime();
      const startDate = (p['startTime']) ? +p['startTime'] : momentJ(endDate).startOf('jMonth').toDate().getTime() ;
      this.title.emit('Loading List');
      this.dateRange = {
        startDate: new Date(startDate),
        endDate: new Date(endDate)
      };
      this.dateRangeSubTitle.emit([this.dateRange, PgDateRangeTitleEnum.persian]);
      this.updateTable(company, startDate, endDate, this.editRecord, this._router, this._lpgDistService);
      });
  }

  updateTable(
    company: string,
    startDate: number,
    endDate: number,
    editRecord : (selectedRecord: LPGDistData, _router: Router) => void,
    router: Router,
    lpgDistService: LPGDistService
  ) {
      forkJoin([
        this._organisationService.get(),
        this._lpgDistService.getOrganisationSummary(
          company,
          startDate,
          endDate)
      ]).subscribe(results => {
        this.organisations = results[0];
        this._organisationsCache = this.organisations.reduce((hashMap, organisation) => {
          hashMap[organisation.name] = organisation;
          return hashMap;
        }, {});
        this.selectedOrganisation = this._organisationsCache[company];
        this.setToolbar();
        const data = results[1].sort((l1, l2) => l1.loadTimestamp - l2.loadTimestamp);
        this.dtElement.dtOptions.columns = this.columnsFormatter();
        this.dtElement.dtOptions.columnDefs = [{targets: '_all', defaultContent: ''}];
        if (!this.isDataTablesInitialised) {
          this.dtTrigger.next(null);
        }
        this.dtElement.dtInstance.then((dtInstance) => {
          dtInstance.clear().draw();
          dtInstance.rows.add(data).draw();
          dtInstance.on('click', 'tbody tr td button', function () {
            if (this.id === 'edit') {
              editRecord(dtInstance.row(this.closest('tr')).data(), router);
            } else if (this.id === 'delete') {
              lpgDistService.delete(dtInstance.row(this.closest('tr')).data()).subscribe(() => {
                dtInstance.row(this.closest('tr')).remove().draw();
              });
            }
          });
          dtInstance.on('change', 'tbody tr td input', function () {
            if (this.id === 'firstCheck') {
              const lpgDistData: LPGDistData = dtInstance.row(this.closest('tr')).data();
              lpgDistData.unload = !lpgDistData.unload;
              lpgDistService.update(lpgDistData).subscribe(() => {console.log('data modified');});
            } else if (this.id === 'confirm') {
              const lpgDistData: LPGDistData = dtInstance.row(this.closest('tr')).data();
              lpgDistData.verify = !lpgDistData.verify;
              lpgDistService.update(lpgDistData).subscribe(() => {console.log('data modified');});
            }
          });
        });
        this.isDataTablesInitialised = true;
    });
  }

  hasRole(roleName: string) {
    return this._keycloakService.hasRole(roleName);
  }

  editRecord(selectedRecord: LPGDistData, _router: Router) {
    if(selectedRecord.billOfLadingNumber === '') {
      _router.navigate(['/lpg-dist/data-entry/niopdc',
      encodeURIComponent(selectedRecord.companyId),
      encodeURIComponent(selectedRecord.loadTimestamp.toString()),
      encodeURIComponent(selectedRecord.foreignKey)])
      .then(_ => _);
    } else{
      _router.navigate(['/lpg-dist/data-entry/edit',
      encodeURIComponent(selectedRecord.companyId),
      encodeURIComponent(selectedRecord.loadTimestamp.toString()),
      encodeURIComponent(selectedRecord.billOfLadingNumber)])
      .then(_ => _);
    }
  }

  findOrganisationName(organisationId: string) {
    const organisation = this._organisationsCache[organisationId];
    return (organisation) ? organisation.nameTranslations['fa'] : '';
  }

  xlsxReport() {
    this._lpgDistService.downloadLoadingExcel(this.selectedOrganisation.name,
      this.dateRange.startDate.getTime(),
      this.dateRange.endDate.getTime()).subscribe(file => {
        saveAs(file.body, `lpg-dist-loadings.xlsx`);
      });
  }

  refresh(item: ToolbarItem) {
    this._settings = {
      companyId: item.companyId};
    this._userLocalStorage.save('LpgDistDetailComponent', this._settings);
    this._router.navigate(['lpg-dist/detail'], { queryParams: {
      company: this.organisations.find(o => o.name === item.companyId).name,
      startTime: item.dateRange.startDate.getTime(),
      endTime: item.dateRange.endDate.getTime(),
    }});
  }

}
