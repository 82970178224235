import { Component, EventEmitter, OnInit } from '@angular/core';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import { Vessel, VesselType, VesselsService } from '../../vessels.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { PgMessagesService, MessageLevel } from 'src/app/shared/services/pg-messages.service';
import { ActionType, DataBaseActionService } from 'src/app/shared/services/database-action.service';
import { DocumentReference } from 'src/app/documents/documents.service';

@Component({
  selector: 'app-vessel-spec',
  templateUrl: './vessel-spec.component.html',
})
export class VesselSpecComponent implements OnInit {
  vessel: Vessel;
  owners: Organisation[];
  technicals: Organisation[];
  commercials: Organisation[];
  owner: Organisation;
  technical: Organisation;
  commercial: Organisation;
  vesselBuilder: Organisation;
  builders: Organisation[];
  manager: Organisation;
  managers: Organisation[];
  protector: Organisation;
  protectors: Organisation[];

  vesselTypes: Observable<VesselType[]>;
  title = new EventEmitter<string>();

  constructor(private _vesselsService: VesselsService,
    private _activateRoute: ActivatedRoute,
    private _organisationService: OrganisationService,
    private _pgMessagesService: PgMessagesService,
    private _actionService: DataBaseActionService,
    private _router: Router) { }

  ngOnInit(): void {
    this.vesselTypes = this._vesselsService.getVesselTypes();
    this._organisationService.get().subscribe(
      oranisation => {
        this.owners = oranisation.filter(
          (x) => x.properties['owner'] === true
        );
        this.technicals = oranisation.filter(
          (x) => x.properties['commercial'] === true
        );
        this.commercials = oranisation.filter(
          (x) => x.properties['technical'] === true
        );
        this.builders = oranisation.filter(
          (x) => x.properties['vesselBuilder'] === true
        );
        this.protectors = oranisation.filter(
          (x) => x.properties['vesselProtector'] === true
        );
        this.managers = oranisation.filter(
          (x) => x.properties['vesselManager'] === true
        );
      }
    );
    this._activateRoute.params.subscribe(params => {
      const imo = params['imo'];
      if (imo) {
        this._vesselsService.getVessel(imo).subscribe(x => this.title.emit(`Spec For ${x.name.name}`));
        forkJoin([
          this._vesselsService.getVessel(imo),
          this._organisationService.get()
        ]).subscribe(result => {
          this.vessel = result[0];
          if(!this.vessel.spec.dryDock) {
            this.vessel.spec.dryDock = {
              nextDryDock: null,
              lastDryDock: null
            };
          }
          if(!this.vessel.spec.charterPartySpeed) {
            this.vessel.spec.charterPartySpeed = {
              economicalLadenSpeed: null,
              maximumBallastSpeed: null
            };
          }
          this.owner = result[1].find(x => this.vessel.spec.ownershipOperationProtection.ownerId === x.name);
          this.technical = result[1].find(x => this.vessel.spec.ownershipOperationProtection.technicalOperatorId === x.name);
          this.vesselBuilder = result[1].find(x => this.vessel.spec.ownershipOperationProtection.builderId === x.name);
          this.protector = result[1].find(x => this.vessel.spec.ownershipOperationProtection.protectionIndemnityId === x.name);
          this.commercial = result[1].find(x => this.vessel.spec.ownershipOperationProtection.commercialOperatorId === x.name);
          this.manager = result[1].find(x => this.vessel.spec.ownershipOperationProtection.managerId === x.name);
        });
      }
    });
  }

  findOrganisationName(index: Organisation, subject: string) {
    if(subject === 'owner') {
      this.vessel.spec.ownershipOperationProtection.ownerId = index.name;
    } else if(subject === 'technical') {
      this.vessel.spec.ownershipOperationProtection.technicalOperatorId = index.name;
    } else if(subject === 'commercial') {
      this.vessel.spec.ownershipOperationProtection.commercialOperatorId = index.name;
    } else if(subject === 'protector') {
      this.vessel.spec.ownershipOperationProtection.protectionIndemnityId = index.name;
    } else if(subject === 'builder') {
      this.vessel.spec.ownershipOperationProtection.builderId = index.name;
    } else if(subject === 'manager') {
      this.vessel.spec.ownershipOperationProtection.managerId = index.name;
    }
  }


  save() {
    this.vessel.actions.push(this._actionService.userAction(ActionType.Update));
    this._vesselsService.updateVessel(this.vessel).subscribe(() => {
      this._pgMessagesService.publishMessage({
        level: MessageLevel.Info,
        topic: 'Vessel',
        message: `The vessel ${this.vessel.IMO} information edited.`
      });
    },
      () => {
        this._pgMessagesService.publishMessage({
          level: MessageLevel.Error,
          topic: 'Vessel',
          message: 'There is a problem to add this vessel.'
        });
      });
    }

    uploadDocument(r: DocumentReference) {
      this.vessel.spec.shipParticularId = r.id;
    }
  }
