import { Component, Output, EventEmitter } from '@angular/core';
import { Organisation } from 'src/app/organisation/organisation.service';
import { DateRange } from 'src/app/shared/components';
import { ToolbarItem } from 'src/app/lpg-dist/lpg-dist.service';


@Component({
  selector: 'app-lpg-dist-toolbar',
  templateUrl: './lpg-dist-toolbar.component.html'
})
export class LpgDistToolbarComponent {

  @Output()
  itemsSelected: EventEmitter<ToolbarItem> = new EventEmitter<ToolbarItem>();

  visibleOrganisation = false;
  organisations: Organisation[];
  dateRange: DateRange = {
    startDate: new Date(),
    endDate: new Date()
  };
  language: string;
  singleDate: boolean;
  selectedOrganisation: Organisation;
  toolbarItem: ToolbarItem;
  jalali: boolean;


  selectedDateRangeChanged(e: any) {
    this.dateRange = {
      startDate : e.startDate,
      endDate: e.endDate
    };
  }

  onSubmit() {
    this.toolbarItem = {
      dateRange: this.dateRange,
      companyId: (this.selectedOrganisation) ? this.selectedOrganisation.name : null,
      selectedCalander: this.jalali
    };

    this.itemsSelected.emit(this.toolbarItem);
  }

}
