<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">SPEC</h3>
            </div>
            <form class="form-horizontal">
                <div class="card-body">
                    <div class="form-group row">
                        <label for="newVesselYearBuilt"
                            class="col-form-label col-md-2">Year Built</label>
                        <div class="col-md-4">
                            <input type="number" class="form-control"
                                name="newVesselYearBuilt"
                                id="newVesselYearBuilt"
                                [(ngModel)]="vessel?.spec.yearBuilt"
                                tabindex="1" required>
                        </div>
                        <label for="newVesselaisVesselType"
                            class="col-form-label col-md-2">Vessel Type</label>
                        <div class="col-md-4">
                            <select class="form-control"
                                name="newVesselaisVesselType"
                                id="newVesselaisVesselType"
                                [(ngModel)]="vessel?.spec.vesselType"
                                tabindex="2" required>
                                <option
                                    *ngFor="let vesselType of vesselTypes | async"
                                    value="{{vesselType.name}}">
                                    {{vesselType.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="newVesselGRT"
                            class="col-form-label col-md-2 ">GRT (ton)</label>
                        <div class="col-md-4">
                            <input type="number" class="form-control"
                                name="newVesselGRT" id="newVesselGRT"
                                [(ngModel)]="vessel?.spec.dimensionsCapacities.GRT" tabindex="3"
                                required>
                        </div>
                        <label for="newVesselNRT"
                            class="col-form-label col-md-2  ">NRT (ton)</label>
                        <div class="col-md-4">
                            <input type="number" class="form-control"
                                name="newVesselNRT" id="newVesselNRT"
                                [(ngModel)]="vessel?.spec.dimensionsCapacities.NRT" tabindex="4"
                                required>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="newVesselCapacity"
                            class="col-form-label col-md-2">Capacity
                            (m<sup>3</sup>)</label>
                        <div class="col-md-4">
                            <input type="number" class="form-control"
                                name="newVesselCapacity" id="newVesselCapacity"
                                [(ngModel)]="vessel?.spec.dimensionsCapacities.capacity" tabindex="5"
                                required>
                        </div>
                        <label for="newVesselDraft"
                            class="col-form-label col-md-2">Draft
                            (meter)</label>
                        <div class="col-md-4">
                            <input type="number" class="form-control"
                                name="newVesselDraft" id="newVesselDraft"
                                [(ngModel)]="vessel?.spec.dimensionsCapacities.draft" tabindex="6"
                                required>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="newVesselDeadWeight"
                            class="col-form-label col-md-2">Dead weight
                            (ton)</label>
                        <div class="col-md-4">
                            <input type="number" class="form-control"
                                name="newVesselDeadWeight"
                                id="newVesselDeadWeight"
                                [(ngModel)]="vessel?.spec.dimensionsCapacities.deadWeight"
                                tabindex="7" required>
                        </div>
                        <label for="newVesselLengthOverall"
                            class="col-form-label col-md-2">LOA (meter)</label>
                        <div class="col-md-4">
                            <input type="number" class="form-control"
                                name="newVesselLengthOverall"
                                id="newVesselLengthOverall"
                                [(ngModel)]="vessel?.spec.dimensionsCapacities.LOA" tabindex="8"
                                required>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="newVesselBeem"
                            class="col-form-label col-md-2">Beam (meter)</label>
                        <div class="col-md-4">
                            <input type="number" class="form-control"
                                name="newVesselBeem" id="newVesselBeem"
                                [(ngModel)]="vessel?.spec.dimensionsCapacities.beam" tabindex="9"
                                required>
                        </div>
                        <label for="lightShip"
                            class="col-form-label col-md-2">Lightship
                            (ton)</label>
                        <div class="col-md-4">
                            <input type="number" class="form-control"
                                name="lightShip" id="lightShip"
                                [(ngModel)]="vessel?.spec.dimensionsCapacities.lightShip"
                                tabindex="10">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="email" class="col-form-label col-md-2">Email
                            address</label>
                        <div class="col-md-4">
                            <input type="email" placeholder="name@example.com"
                                class="form-control" name="email"
                                id="email" [(ngModel)]="vessel?.spec.email"
                                tabindex="11">
                        </div>
                        <label for="numberOfTanks"
                            class="col-form-label col-md-2">Number Of Tanks</label>
                        <div class="col-md-4">
                            <input type="number" class="form-control"
                                name="numberOfTanks" id="numberOfTanks"
                                [(ngModel)]="vessel?.spec.dimensionsCapacities.numberOfTanks"
                                tabindex="12" required>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="lastDryDock"
                            class="col-form-label col-md-2">Last dry dock</label>
                        <div class="col-md-4">
                            <input
                                type="date"
                                id="lastDryDock"
                                name="lastDryDock"
                                [(ngModel)]="vessel?.spec.dryDock.lastDryDock"
                                tabindex="13"
                                class="form-control" />
                        </div>
                        <label for="nextDryDock"
                            class="col-form-label col-md-2">Next dry dock</label>
                        <div class="col-md-4">
                            <input
                                type="date"
                                id="nextDryDock"
                                name="nextDryDock"
                                [(ngModel)]="vessel?.spec.dryDock.nextDryDock"
                                tabindex="14"
                                class="form-control" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="owner"
                            class="col-form-label col-md-2">Owner</label>
                        <div class="col-md-4">
                            <app-organisation-selector
                                id="owner"
                                class="pg-component"
                                [organisation]="owner"
                                (organisationChange)="findOrganisationName($event, 'owner')"
                                [organisationLanguage]="'en'"
                                name="owner"
                                [organisations]="owners"
                                tabindex="15"
                                required>
                            </app-organisation-selector>
                        </div>
                        <label for="technicalOperatorId"
                            class="col-form-label col-md-2">Technical
                            Operator</label>
                        <div class="col-md-4">
                            <app-organisation-selector
                                id="technical"
                                class="pg-component"
                                [organisation]="technical"
                                (organisationChange)="findOrganisationName($event, 'technical')"
                                [organisationLanguage]="'en'"
                                name="technical"
                                tabindex="16"
                                [organisations]="technicals"
                                required>
                            </app-organisation-selector>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="vesselBuilder"
                            class="col-form-label col-md-2">Vessel Builder</label>
                        <div class="col-md-4">
                            <app-organisation-selector
                                id="vesselBuilder"
                                class="pg-component"
                                [organisation]="vesselBuilder"
                                (organisationChange)="findOrganisationName($event, 'builder')"
                                [organisationLanguage]="'en'"
                                name="vesselBuilder"
                                [organisations]="builders"
                                tabindex="17"
                                required>
                            </app-organisation-selector>
                        </div>
                        <label for="protector"
                            class="col-form-label col-md-2">Protection and Indemnity</label>
                        <div class="col-md-4">
                            <app-organisation-selector
                                id="protector"
                                class="pg-component"
                                [organisation]="protector"
                                (organisationChange)="findOrganisationName($event, 'protector')"
                                [organisationLanguage]="'en'"
                                name="protector"
                                tabindex="18"
                                [organisations]="protectors"
                                required>
                            </app-organisation-selector>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="manager"
                            class="col-form-label col-md-2">Manager
                            Oprator</label>
                        <div class="col-md-4">
                            <app-organisation-selector
                                id="manager"
                                class="pg-component"
                                [organisation]="manager"
                                (organisationChange)="findOrganisationName($event, 'manager')"
                                [organisationLanguage]="'en'"
                                name="manager"
                                tabindex="19"
                                [organisations]="managers"
                                required>
                            </app-organisation-selector>
                        </div>
                        <label for="economicalLadenSpeed"
                            class="col-form-label col-md-2">Charter party speed</label>
                        <div class="col-md-2">
                            <input type="number" class="form-control"
                                name="economicalLadenSpeed" id="economicalLadenSpeed"
                                placeholder="Economical Laden Speed"
                                [(ngModel)]="vessel?.spec.charterPartySpeed.economicalLadenSpeed"
                                tabindex="20">
                        </div>
                        <div class="col-md-2">
                            <input type="number" class="form-control"
                                name="maximumBallastSpeed" id="maximumBallastSpeed"
                                placeholder="Maximum Ballast Speed"
                                [(ngModel)]="vessel?.spec.charterPartySpeed.maximumBallastSpeed"
                                tabindex="20">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="commercialOperatorId"
                            class="col-form-label col-md-2">Commercial
                            Oprator</label>
                        <div class="col-md-4">
                            <app-organisation-selector
                                id="commercial"
                                class="pg-component"
                                [organisation]="commercial"
                                (organisationChange)="findOrganisationName($event, 'commercial')"
                                [organisationLanguage]="'en'"
                                name="commercial"
                                tabindex="21"
                                [organisations]="commercials"
                                required>
                            </app-organisation-selector>
                        </div>
                        <label for="licenseNumber"
                            class="col-form-label col-md-2 ">File</label>
                        <div class="col-md-4">
                            <app-chunk-file-uploader
                                [accept]="'.xlsx'"
                                name="document"
                                (filesChanged)="uploadDocument($event)">
                            </app-chunk-file-uploader>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="newVessel"
                            class="col-form-label col-md-4"></label>
                        <div class="col-md-8">
                            <input type="submit" id="newVessel"
                                class="btn btn-primary" value="Save"
                                (click)="save()">
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
