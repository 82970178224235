import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { getApiUrl } from '../../../environments/environment.service';

@Injectable({
  providedIn: 'root'
})
export class HttpWrapper {

  constructor(private _http: HttpClient) { }

  get<T>(url: string): Observable<T> {
    return this._http.get<T>(this.constructUrl(url), this.getHeaderOptions());
  }

  post<T, U>(url: string, body: U) {
    const constructedUrl = this.constructUrl(url);
    const bodyJson = JSON.stringify(body);

    return this._http.post<T>(constructedUrl, bodyJson, this.getHeaderOptions());
  }

  put<T, U>(url: string, body: U) {
    const constructedUrl = this.constructUrl(url);
    const bodyJson = JSON.stringify(body);

    return this._http.put<T>(constructedUrl, bodyJson, this.getHeaderOptions());
  }

  delete<T>(url: string) {
    const constructedUrl = this.constructUrl(url);
    return this._http.delete<T>(constructedUrl, this.getHeaderOptions());
  }

  postMultiPart<T>(url: string, body: FormData) {
    const constructedUrl = this.constructUrl(url);
    return this._http.post<T>(constructedUrl, body, {
      headers: {
        timeout: '600'
      }
    });
  }

  download(url: string) {
    const constructedUrl = this.constructUrl(url);
    return this._http.get<Blob>(constructedUrl, {
      responseType: 'blob' as 'json',
      observe: 'response',
    }).pipe(map(i => i as HttpResponse<Blob>));
  }

  constructUrl(url: string) {
    return `${getApiUrl()}/${url}`;
  }

  private getHeaderOptions() {
    return {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        timeout: '600'
      }
    };
  }
}
