import { Injectable } from '@angular/core';
import { HttpWrapper } from '../shared/services';
import { Observable } from 'rxjs';
import { LookupService } from '../shared/services/lookup.service';


export interface PriceIndex {
  provider: string;
  timestamp: number;
  index: string;
  productId: string;
  price: number;
  isDeleted: boolean;
}

export interface PriceIndexDescription {
  indexName: string;
  products: string[];
}

export interface PriceProvider {
  providerName: string;
  indexes: PriceIndexDescription[];
}

export interface MarketPublicationConfig {
  publication: string;
  senderEmailAddress: string;
  datePattern: string;
  attachmentRegexPattern: string;
}

export interface MarketPublisher {
  publisher: string;
  receiverEmailAddress: string;
  publications: MarketPublicationConfig[];
}

export interface MarketDoc {
  publisher: string;
  fileName: string;
  timestamp: number;
  docId: string;
}

export interface FederalReserveBankData {
  effectiveDate: string;
  indexName: string;
  percent?: number;
  percentRate?: number;
  percentPercentile1?: number;
  percentPercentile25?: number;
  percentPercentile75?: number;
  percentPercentile99?: number;
  volumeInBillions?: number;
  footnoteId?:number;
  revisionIndicator?: string
  average30day?: number;
  average90day?: number;
  average180day?: number;
  index?: number;
  targetRateFrom?: number;
  targetRateTo?: number;
  targetRange?: number;
  intraDayLow?:number;
  intraDayHigh?:number;
  stdDeviation?: number;
}

@Injectable()
export class MarketService {

  constructor(private _httpWrapper: HttpWrapper,
              private _lookupService: LookupService) { }

  add(data: PriceIndex): Promise<PriceIndex> {
    return this._httpWrapper.post<PriceIndex, PriceIndex>('pricing', data).toPromise();
  }

  getPrices(provider: string, startTimestamp: number, endTimestamp: number): Observable<PriceIndex[]> {
    return this._httpWrapper.get<PriceIndex[]>(`pricing/${provider}/${startTimestamp}/${endTimestamp}`);
  }

  getExcelReport(provider: string, startTimestamp: number, endTimestamp: number) {
    return this._httpWrapper.download(`pricing/excel/${provider}/${startTimestamp}/${endTimestamp}`);
  }
  getAll(startTimestamp: number, endTimestamp: number): Promise<PriceIndex[]> {
    return this._httpWrapper.get<PriceIndex[]>(`pricing/${startTimestamp}/${endTimestamp}`).toPromise();
  }

  getFederalReserveData(startDate: string, endDate: string): Promise<FederalReserveBankData[]> {
    return this._httpWrapper.get<FederalReserveBankData[]>(`pricing/sofr/${startDate}/${endDate}`).toPromise();
  }

  getPublisherDocs(publisher: string, startTimestamp: number, endTimestamp: number): Observable<MarketDoc[]> {
    return this._httpWrapper.get<MarketDoc[]>(`pricing/documents/${publisher}/${startTimestamp}/${endTimestamp}`);
  }

  getProviders() {
    return this._httpWrapper.get<PriceProvider[]>('pricing/providers');
  }

  getMarketPublishers() {
    return this._httpWrapper.get<MarketPublisher[]>('publishers');
  }

  importPriceFile(publisher: string, publicationName: string, date: string, fileList: FileList): Observable<PriceIndex[]> {
    return this._httpWrapper.postMultiPart(
      `pricing/reimport/${publisher}/${encodeURIComponent(publicationName)}/${date}`, this.toFormData(publisher, fileList));
  }

  private toFormData(docType: string, fileList: FileList) {
    const formData = new FormData();
    formData.append('documentType', docType);
    formData.append('file', fileList.item(0));
    return formData;
  }
}
