import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { forkJoin } from 'rxjs';
import { DocumentReference, DocumentService } from 'src/app/documents/documents.service';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import { LookupService } from 'src/app/shared/services/lookup.service';
import { Crew, CrewAppraisal, CrewChange } from 'src/app/transportation/vessels/crew-change/crew-change.service';
import { CrewChangeServices } from 'src/app/transportation/vessels/crew-change/crew-change.service';
import { KeycloakService } from 'src/app/authentication/keycloak.service';
import { CrewProfileToolbarComponent } from './crew-profile-toolbar/crew-profile-toolbar.component';
import { PgToolbarComponent } from 'src/app/shared/components/title-bar/title-bar.component';
import { saveAs } from 'file-saver';
import { TimelineItem } from 'src/app/shared/components/timeline/timeline.component';

@Component({
  selector: 'app-crew-profile',
  templateUrl: './crew-profile.component.html',
})
export class CrewProfileComponent implements OnInit, PgToolbarComponent {

  title = new EventEmitter<string>();
  toolbarComponentType = CrewProfileToolbarComponent;
  toolbarComponent: CrewProfileToolbarComponent;
  crew: Crew;
  message: string;
  rankItem = [];
  edit: boolean;
  nationalityItem: Organisation[];
  crewId: string;
  nationality: Organisation;
  minDateExpiryDate = moment().format('YYYY-MM-DD');
  maxDate = moment().format('YYYY-MM-DD');
  _organisations: Organisation[];
  rate: any[];

  crewChangeTimeline: TimelineItem[];

  constructor(
     private _crewChangeService: CrewChangeServices,
     private _activateRoute: ActivatedRoute,
     private _lookupService: LookupService,
     private _organisationService: OrganisationService,
     private _keycloakService: KeycloakService,
     private _documentService: DocumentService) { }

  ngOnInit(): void {
    this.nationalityItem = [];
    this.title.emit('Crew');
    this._activateRoute.paramMap.subscribe(p => {
      this.crewId = p.get('id');
      forkJoin([
        this._organisationService.get(),
        this._lookupService.get('ranks'),
      ]).subscribe((result) => {
        this._organisations = result[0];
        this.nationalityItem = result[0].filter(x => x.properties['country'] === true);
        this.rankItem = result[1].items;
        this.initCrew();
      });
    });
  }

  disableEdit() {
    return !this._keycloakService.hasRole('vessels-crew-change-editor');
  }

  initCrew() {
    if (this.crewId) {
      this.edit = true;
      this._crewChangeService.getOneCrewById(this.crewId).subscribe(x => {
        this.crew = x;
        this.rate = this.calculateRating(this.crew.appraisals);
        this.crew.crewChanges = x.crewChanges.filter(crewChange => crewChange.isDeleted === false);
        this.toolbarComponent.crew= this.crew;
        this.nationality = this.nationalityItem.find(z => z.name === x.nationality);
        this.calculateAge();
        this.crewChangeTimeline = this.convertCrewChangeToTimeline(this.crew.crewChanges);
    });
    }
  }

  uploadSeaManFile(r: DocumentReference) {
    this.crew.seamanDetails.seamanFileId = r.id;
  }

  uploadPassportFile(v: DocumentReference) {
    this.crew.passportDetails.passportFileId = v.id;
  }

  uploadCvFile(v: DocumentReference) {
    this.crew.cvDocumentId = v.id;
  }

  nationalityFormatter(data: Organisation) {
    return data.nameTranslations['en'];
  }

  photoUploaded(docReference: DocumentReference) {
    this.crew.photoDocumentId = docReference.id;
  }

  calculateAge() {
    return  moment().diff(moment(this.crew.birthDate), 'year');
  }

  getStatus() {
    return this._crewChangeService.getCrewStatus(this.crew);
  }

  planTime(crewChange: CrewChange, selectedPlan: string) {
    if(this._organisations) {
      return this._crewChangeService.planTime(crewChange, selectedPlan, this._organisations);
    }
  }

  planPlace(crewChange: CrewChange, selectedPlan: string) {
    if(this._organisations) {
      return this._crewChangeService.planPlace(crewChange, selectedPlan, this._organisations);
    }
  }

  onboardTimeMonthsDays(crewChange: CrewChange) {
    return  this._crewChangeService.onboardTimeMonthsDays(crewChange);
  }

  travelTimeDays(crewChange: CrewChange) {
    return this._crewChangeService.travelTimeDays(crewChange);
  }

  getLastCrewChange(crewChanges: CrewChange[]) {
    if(crewChanges.length > 0) {
      return this._crewChangeService.getLastCrewChange(crewChanges);
    } else {
      return '';
    }
  }

  downLoadDocumnet(id) {
    this._documentService.download(id).subscribe(f => {
      saveAs(f.body, `${id}.pdf`);
    });
  }

  convertCrewChangeToTimeline(crewChanges: CrewChange[]) {
    const sorted = crewChanges
      .flatMap(crewChange => crewChange.item
        .sort((i1, i2) => i2.time - i1.time)
        .map(i => (
          {
            plan: i.plan,
            place: i.place,
            time: i.time,
            vesselName: crewChange.vesselName,
            rank: crewChange.rank,
          }
        )))
      .sort((i1, i2) => i2.time - i1.time);
      const t = sorted.map ((plan, index) =>  {
        const timeline: TimelineItem = {
          title: plan.plan,
          description: this.description(plan, sorted[index + 1]),
          date: moment(plan.time).format('YYYY-MM-DD'),
          iconStyles: (plan.plan ==='Off Board' || plan.plan === 'Outgoing Flight') ? 'bg-warning' : 'bg-success'
        };
        return timeline;
      });
    return t;
  }

  findDiff(plan, before, timeUnit) {
    if(before) {
      if(timeUnit === 'months') {
        return `${Math.round(+ moment(plan.time).diff(moment(before.time), 'months', true).toFixed(2))} months
        (${moment(plan.time).diff(moment(before.time), 'days', true).toFixed(2)} days)`;
      } else if (timeUnit === 'days') {
        return `${moment(plan.time).diff(moment(before.time), 'days', true).toFixed(2)} days
        (${moment(plan.time).diff(moment(before.time), 'hours', true).toFixed(2)} hours)`;
      }
    } else {
      return '';
    }
  }

  description(plan: any , before) {
    if(plan.plan === this._crewChangeService.getPlanningPhases()[0]) {
      return `${this.crew.firstName} ${this.crew.lastName} travelled from 
        ${this._organisations.find(o => o.name === plan.place).nameTranslations['en']} 
        He was on rest about  ${this.findDiff(plan, before, 'months')} before starting travel.
        He nominated for ${plan.rank}`;
    } else if(plan.plan === this._crewChangeService.getPlanningPhases()[1]) {
      return `On Boarded on ${plan.vesselName} in 
      ${this._organisations.find(o => o.name === plan.place).nameTranslations['en']}
      He travelled about ${this.findDiff(plan, before, 'days')} before on signing `;
    } else if(plan.plan === this._crewChangeService.getPlanningPhases()[2]) {
      return `Unsigned from ${plan.vesselName} in 
      ${this._organisations.find(o => o.name === plan.place).nameTranslations['en']}
      He was on board about ${this.findDiff(plan, before, 'months')}`;
    } else if(plan.plan === this._crewChangeService.getPlanningPhases()[3]) {
      return `He flight to ${this._organisations.find(o => o.name === plan.place).nameTranslations['en']}
      to back to his home His flight duration was ${this.findDiff(plan, before, 'days')}`;
    }
  }

  calculateRating(crewAppraisals: CrewAppraisal[]) {
    return this._crewChangeService.calculateRating(crewAppraisals);
  }

}